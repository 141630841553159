import { render, staticRenderFns } from "./SendEmail.vue?vue&type=template&id=77bfa336"
import script from "./SendEmail.vue?vue&type=script&lang=js"
export * from "./SendEmail.vue?vue&type=script&lang=js"
import style0 from "./SendEmail.vue?vue&type=style&index=0&id=77bfa336&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports